/** @jsx jsx */
import { jsx, Link } from "theme-ui";
import {
  AiFillGithub,
  AiFillLinkedin,
  AiOutlineCodepen,
  AiOutlineTwitter,
} from "react-icons/ai";

const Socials = () => {
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "2rem",
        px: "1rem",
      }}
    >
      <Link
        href="https://github.com/chasejfen"
        sx={{ textDecoration: "none", color: "inherit" }}
      >
        <AiFillGithub sx={{ variant: "iconButton.primaryLarge" }} />
      </Link>
      <Link
        href="https://www.linkedin.com/in/chase-fenske-a01848149/"
        sx={{ textDecoration: "none", color: "inherit" }}
      >
        <AiFillLinkedin sx={{ variant: "iconButton.primaryLarge" }} />
      </Link>
      <Link
        href="https://codepen.io/chase-fenske"
        sx={{ textDecoration: "none", color: "inherit" }}
      >
        <AiOutlineCodepen sx={{ variant: "iconButton.primaryLarge" }} />
      </Link>
      <Link
        href="https://twitter.com/Chase_Fenske"
        sx={{ textDecoration: "none", color: "inherit" }}
      >
        <AiOutlineTwitter sx={{ variant: "iconButton.primaryLarge" }} />
      </Link>
    </div>
  );
};

export default Socials;
