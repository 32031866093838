/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/Layout";
import { Label, Input, Textarea } from "theme-ui";
import { Box, Button } from "theme-ui";
import { useState } from "react";
import Socials from "../components/Socials";

const Contact = () => {
  const [email, setEmail] = useState(false);
  const [validE, setValidE] = useState(true);
  const [subject, setSubject] = useState(false);
  const [validS, setValidS] = useState(true);
  const [message, setMessage] = useState(false);
  const [validM, setValidM] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [submited, setSubmited] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    if (validE && validM && validS && email && subject && message) {
      setIsSubmiting(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ email, subject, message });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      await fetch(process.env.GATSBY_API_URL, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setSubmited(true);
          setIsSubmiting(result.message);
        })
        .catch((error) => {
          setSubmited(true);
          console.log("error", error);
        });
    } else {
      console.log("nope");
    }
  };

  return (
    <Layout styling={"layout.secondary"} additionalStyles={{maxWidth: "40rem"}}>
      <div sx={{ textAlign: "justify" }}>
        {!submited ? (
          <div>
            <div sx={{ textAlign: "center", marginBottom: "-1rem" }}>
              <h2>Contact Me</h2>
              <p>
                If you would like to message me please leave your information
                below.
              </p>
              <br />
            </div>

            <Box as="form" onSubmit={(e) => submit(e)}>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                mb={3}
                disabled={isSubmiting}
                sx={{
                  boxShadow: validE
                    ? ""
                    : (t) => `0 0 0 1px ${t.colors.contrast}`,
                  borderColor: validE ? "text" : "contrast",
                  marginBottom: "1rem",
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onBlur={(e) => {
                  const re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  setValidE(re.test(String(email).toLowerCase()));
                }}
              />
              <Label htmlFor="subject">Subject</Label>
              <Input
                name="subject"
                id="subject"
                mb={3}
                disabled={isSubmiting}
                sx={{
                  boxShadow: validS
                    ? ""
                    : (t) => `0 0 0 1px ${t.colors.contrast}`,
                  borderColor: validS ? "text" : "contrast",
                  marginBottom: "1rem",
                }}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                onBlur={(e) => {
                  setValidS(subject?.length > 4);
                }}
              />
              <Label htmlFor="message">Message</Label>
              <Textarea
                name="message"
                id="message"
                rows={6}
                mb={3}
                disabled={isSubmiting}
                sx={{
                  boxShadow: validM
                    ? ""
                    : (t) => `0 0 0 1px ${t.colors.contrast}`,
                  borderColor: validM ? "text" : "contrast",
                }}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                onBlur={(e) => {
                  setValidM(message?.length > 10);
                }}
              />
              <Button
                sx={{
                  border: "none",
                  borderRadius: "10px",
                  color: "text",
                  fontSize: "sm",
                  fontWeight: "bold",
                  px: "2rem",
                  py: "1rem",
                  bg: "highlighted",
                  "&:hover": {
                    color: "background",
                    bg: "text",
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </div>
        ) : (
          <div sx={{ textAlign: "center" }}>
            {isSubmiting === "success" ? (
              <div sx={{ marginTop: "20vh" }}>
                <h1>Thanks for reaching out!</h1>
                <h2>I'll respond when I get your message</h2>
              </div>
            ) : (
              <div sx={{ marginTop: "20vh" }}>
                <h1>Looks like something went wrong...</h1>
                <h2>You might have better luck by sending me a DM on social</h2>
              </div>
            )}
          </div>
        )}
        <Socials />
      </div>
    </Layout>
  );
};

export default Contact;
